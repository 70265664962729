.wip-section {
    height: calc(100vh - 60px);
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .wip-section .text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .wip-section .text p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }